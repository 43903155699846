<template>
  <div class="employee">
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="employeeListDF.keyword"
          placeholder="请输入关键字"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="employeeListDF.did"
          clearable
          placeholder="按部门筛选"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="employeeListDF.tid"
          clearable
          placeholder="按班组筛选"
          @focus="teamFocus"
        >
          <el-option
            v-for="item in teamOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getEmployeeList">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table border :data="employeeList" style="width: 1200px" height="400">
      <el-table-column
        label="姓名"
        prop="name"
        align="center"
        width="160"
        fiexd="left"
      ></el-table-column>
      <el-table-column
        label="性别"
        prop="sex"
        align="center"
        width="70"
      ></el-table-column>
      <el-table-column
        label="电话"
        prop="tel"
        align="center"
        width="130"
      ></el-table-column>

      <el-table-column
        label="部门"
        prop="departmentName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="班组"
        prop="tempName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="角色"
        prop="roleName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="岗位"
        prop="gangwei"
        align="center"
      ></el-table-column>
      <el-table-column
        label="入职时间"
        prop="ruzhiDate"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.ruzhiDate ? scope.row.ruzhiDate.split(" ")[0] : "" }}
        </template>
      </el-table-column>
      <el-table-column
        label="资质名称"
        prop="zizhiName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="资质证号"
        prop="zizhiNumber"
        align="center"
      ></el-table-column>
      <el-table-column
        label="资质有效期"
        prop="zizhiValidityDate"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          {{
            scope.row.zizhiValidityDate
              ? scope.row.zizhiValidityDate.split(" ")[0]
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">入职申请审批中</span>
          <span v-if="scope.row.status == 1">在职</span>
          <span v-if="scope.row.status == 2">已拒绝入职</span>
          <span v-if="scope.row.status == 3">修改员工信息审批中</span>
          <span v-if="scope.row.status == 4">离职审批中</span>
          <span v-if="scope.row.status == 5">已离职</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            plain
            @click="seeDetailsHandler(scope.row.id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="employeeListDF.page"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="employeeListDF.limit"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <see-detail v-if="seeDetail_visible" ref="SeeDetailDom"></see-detail>
  </div>
</template>

<script>
import SeeDetail from "./see-detail.vue";
export default {
  components: {
    SeeDetail,
  },
  data() {
    return {
      seeDetail_visible: false,
      departmentOptions: [], //部门列表
      teamOptions: [], // 班组列表
      employeeListDF: {
        did: "",
        tid: "",
        keyword: "",
        limit: 10,
        page: 1,
      },
      totalCount: 0,
      employeeList: [],
    };
  },
  methods: {
    init() {
      this.getEmployeeList();
      this.getDepartmentList();
    },
    teamFocus() {
      this.teamOptions = [];
      if (!this.employeeListDF.did) {
        this.$message.error("请先选择部门");
        return;
      }

      this.$http.team.list({ limit: 99999, page: 1 }).then((data) => {
        if (data && data.code === 0) {
          if (data.data.list.length > 0) {
            data.data.list.forEach((item) => {
              this.teamOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.DepartmentOptions = [];
      this.$http.department.list({ limit: 99999, page: 1 }).then((data) => {
        if (data && data.code === 0) {
          if (data.data.list.length > 0) {
            data.data.list.forEach((item) => {
              this.departmentOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        }
      });
    },
    // 获取员工列表
    getEmployeeList() {
      this.$http.employee.list(this.employeeListDF).then((res) => {
        if (res.data) {
          this.employeeList = res.data.list || [];
          this.totalCount = res.data.totalCount;
        } else {
          this.employeeList = [];
          this.totalCount = 0;
        }
      });
    },

    // 员工离职
    deleteEmployeeHandler(eid) {
      this.$prompt("请输入离职日期", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        this.$http.employee
          .approvalQuit({ eid: eid, quitDate: value })
          .then((res) => {
            if (res && res.code == 0) {
              this.$message.success(res.msg);
              this.getEmployeeList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },

    // 打开查看详情组件
    seeDetailsHandler(id) {
      this.seeDetail_visible = true;
      this.$nextTick(() => {
        this.$refs.SeeDetailDom.init(id);
      });
    },
    // 显示页数发生变化
    sizeChangeHandle(val) {
      this.employeeListDF.limit = val;
      this.employeeListDF.page = 1;
      this.getEmployeeList();
    },
    // 当前页发生变化
    currentChangeHandle(val) {
      this.employeeListDF.page = val;
      this.getEmployeeList();
    },
  },
};
</script>